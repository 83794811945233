






























































import {
  defineComponent,
  useContext,
  ref,
  onMounted,
  onBeforeMount,
  onBeforeUnmount,
  computed
} from '@nuxtjs/composition-api'
import { SfOverlay } from '@storefront-ui/vue'
import HeaderLogo from 'components/theme/Header/Logo.vue';
import { useUiState, useConfig } from '@wemade-vsf/composables';
import { useSearch } from '@wemade-vsf/search';
import { useUser } from '@wemade-vsf/customer';
import MegaMenu from 'components/megamenu/MegaMenu.vue';
import CartNavigation from 'components/theme/Header/CartNavigation.vue';
import MobileNavigationBar from 'components/theme/Header/MobileNavigationBar.vue';
import TopNavigation from 'components/theme/Header/TopNavigation.vue';

export default defineComponent({
  name: 'AppHeader',
  components: {
    CartNavigation,
    HeaderLogo,
    MegaMenu,
    MobileNavigationBar,
    SfOverlay,
    TopNavigation,
    SearchBar: () => import('components/search/SearchBar.vue'),
    SearchResults: () => import(/* webpackPrefetch: true */ 'components/search/SearchResults.vue'),
    MobileSidebar: () => import('components/megamenu/MegaMenuSidebar.vue'),
    TopBar: () => import('components/theme/Header/TopBar.vue')
  },
  props: {
    menuOrientation: {
      type: String,
      default: 'horizontal'
    },
    useSticky: {
      type: Boolean,
      default: false
    }
  },
  setup (props) {
    const { app: { $wm }, $config } = useContext();
    const { 
      isMobileMenuOpen: isMobileMenu,
      toggleMobileMenu,
      hideSearch
    } = useUiState();

    const {
      isSearchOpen
    } = useSearch();
    
    const { isAuthenticated, load: loadUser, user } = useUser();
    const { config } = useConfig();

    const showWishlist = $config.wishlist?.enable ?? true
    const showCart = computed(() => {
      if (!user.value) {
        return !(config.value?.storefront?.hide_cart_button ?? false)
      }
      return !user.value.hide_cart_button
    })

    const topNavigationPosition = $wm?.theme?.topNavigationPosition ?? 'menu'

    const topBarConfig = computed(() => {
      if (config.value?.storefront?.top_bar_block_enabled && config.value?.storefront?.top_bar_block_identifier) {
        return {
          background: config.value.storefront.top_bar_block_background_color,
          identifier: config.value.storefront.top_bar_block_identifier
        }
      }
      return null
    })

    const observeIndicator = ref(null)
    let scrollObserver = null;
    const isSticky = ref(false);
    const sticky = config.value?.storefront?.sticky_header ?? false;

    onBeforeMount(() => {
      if (props.useSticky && typeof window !== 'undefined' && ('IntersectionObserver' in window)) {
        scrollObserver = new IntersectionObserver(([e]) => {
        document.documentElement.classList.toggle('is-sticky', e.intersectionRatio < 1);
      }, { threshold: [1] })
      }
    })
    onMounted(async() => {
      if (!process.server && scrollObserver && observeIndicator.value) {
        scrollObserver.observe(observeIndicator.value);
      }
      if (isAuthenticated.value && !user.value) {
        await loadUser();
      }
    });

    onBeforeUnmount(() => {
      if (!process.server && scrollObserver) {
        scrollObserver.unobserve(observeIndicator.value)
      }
    })

    return {
      isMobileMenu,
      isSearchOpen,
      isSticky,
      sticky,
      observeIndicator,
      showWishlist,
      showCart,
      topBarConfig,
      topNavigationPosition,
      toggleMobileMenu,
      hideSearch
    }
  }
})
