import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _4ddc0905 = () => interopDefault(import('../_templates/pages/blog/Blog.vue' /* webpackChunkName: "" */))
const _7f146505 = () => interopDefault(import('../_templates/pages/checkout/Checkout.vue' /* webpackChunkName: "" */))
const _61fd3424 = () => interopDefault(import('../_templates/pages/checkout/Checkout/Order.vue' /* webpackChunkName: "" */))
const _2e354ffa = () => interopDefault(import('../_templates/pages/checkout/Checkout/Account.vue' /* webpackChunkName: "" */))
const _71b3da22 = () => interopDefault(import('../_templates/pages/checkout/Checkout/ThankYou.vue' /* webpackChunkName: "" */))
const _7dce4a3c = () => interopDefault(import('../_templates/pages/customer/MyAccount/MyAccount.vue' /* webpackChunkName: "" */))
const _910d04ac = () => interopDefault(import('../_templates/pages/customer/MyAccount/AddressesDetails/AddressesDetails.vue' /* webpackChunkName: "" */))
const _636cc8b4 = () => interopDefault(import('../_templates/pages/customer/MyAccount/MyProfile/MyProfile.vue' /* webpackChunkName: "" */))
const _868d842c = () => interopDefault(import('../_templates/pages/customer/MyAccount/OrderHistory/OrderHistory.vue' /* webpackChunkName: "" */))
const _7fd86c14 = () => interopDefault(import('../_templates/pages/customer/MyAccount/AddressesDetails/AddressNew.vue' /* webpackChunkName: "" */))
const _526a85a4 = () => interopDefault(import('../_templates/pages/customer/MyAccount/AddressesDetails/AddressEdit.vue' /* webpackChunkName: "" */))
const _65f5826e = () => interopDefault(import('../_templates/pages/customer/MyAccount/OrderHistory/SingleOrder/SingleOrder.vue' /* webpackChunkName: "" */))
const _78fa701a = () => interopDefault(import('../_templates/pages/faq/Faq.vue' /* webpackChunkName: "" */))
const _638e13e5 = () => interopDefault(import('../_templates/pages/wishlist/Wishlist.vue' /* webpackChunkName: "" */))
const _75815005 = () => interopDefault(import('../_templates/pages/search/Search.vue' /* webpackChunkName: "" */))
const _00735da5 = () => interopDefault(import('../_templates/pages/payment-svea/Checkout.vue' /* webpackChunkName: "" */))
const _85e07bd6 = () => interopDefault(import('../pages/catalog/Category.vue' /* webpackChunkName: "pages/catalog/Category" */))
const _3e8ad336 = () => interopDefault(import('../_templates/pages/customer/MyAccount/ResetPassword.vue' /* webpackChunkName: "" */))
const _3c19dffa = () => interopDefault(import('../_templates/pages/payment-svea/CheckoutConfirmation.vue' /* webpackChunkName: "" */))
const _53da9176 = () => interopDefault(import('../_templates/pages/blog/BlogPost.vue' /* webpackChunkName: "" */))
const _9f7b66d8 = () => interopDefault(import('../_templates/pages/attribute-collection/AttributeCollection.vue' /* webpackChunkName: "" */))
const _668f0c47 = () => interopDefault(import('../_templates/pages/attribute-collection/AttributeCollectionItem.vue' /* webpackChunkName: "" */))
const _8500bd02 = () => interopDefault(import('../_templates/pages/theme/Home.vue' /* webpackChunkName: "" */))
const _0db4b72f = () => interopDefault(import('../_templates/pages/theme/Page.vue' /* webpackChunkName: "" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/aktuelt.html",
    component: _4ddc0905,
    name: "blog___default"
  }, {
    path: "/checkout",
    component: _7f146505,
    name: "checkout___default",
    children: [{
      path: "bestilling",
      component: _61fd3424,
      name: "checkout-order___default"
    }, {
      path: "konto",
      component: _2e354ffa,
      name: "checkout-account___default"
    }, {
      path: "takk",
      component: _71b3da22,
      name: "checkout-thank-you___default"
    }]
  }, {
    path: "/customer",
    component: _7dce4a3c,
    meta: {"titleLabel":"Account"},
    name: "customer___default",
    children: [{
      path: "addresses-details",
      component: _910d04ac,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details___default"
    }, {
      path: "my-profile",
      component: _636cc8b4,
      meta: {"titleLabel":"My Account"},
      name: "customer-my-profile___default"
    }, {
      path: "order-history",
      component: _868d842c,
      meta: {"titleLabel":"Order history"},
      name: "customer-order-history___default"
    }, {
      path: "addresses-details/create",
      component: _7fd86c14,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details-new___default"
    }, {
      path: "addresses-details/edit/:addressId",
      component: _526a85a4,
      meta: {"titleLabel":"Addresses details"},
      props: true,
      name: "customer-addresses-details-edit___default"
    }, {
      path: "order-history/:orderId",
      component: _65f5826e,
      meta: {"titleLabel":"Order history"},
      props: true,
      name: "customer-single-order___default"
    }]
  }, {
    path: "/faq.html",
    component: _78fa701a,
    name: "faq___default"
  }, {
    path: "/onskeliste",
    component: _638e13e5,
    name: "wishlist___default"
  }, {
    path: "/search",
    component: _75815005,
    name: "search___default"
  }, {
    path: "/sveacheckout",
    component: _00735da5,
    name: "sveaCheckout___default"
  }, {
    path: "/catalog/Category",
    component: _85e07bd6,
    name: "catalog-Category___default"
  }, {
    path: "/customer/reset-password",
    component: _3e8ad336,
    alias: "/customer/account/createPassword",
    name: "reset-password___default"
  }, {
    path: "/sveacheckout/confirmation",
    component: _3c19dffa,
    name: "sveaCheckoutConfirmation___default"
  }, {
    path: "/aktuelt/:slug+.html",
    component: _53da9176,
    name: "blogPost___default"
  }, {
    path: "/collection/:slug([^/.]+).html",
    component: _9f7b66d8,
    name: "attributeCollection___default"
  }, {
    path: "/collection/:collection_slug/:slug+.html",
    component: _668f0c47,
    name: "attributeCollectionItem___default"
  }, {
    path: "/",
    component: _8500bd02,
    name: "home___default"
  }, {
    path: "/:slug+",
    component: _0db4b72f,
    name: "page___default"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
