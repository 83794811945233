import Vue from 'vue'

import Meta from 'vue-meta'
import ClientOnly from 'vue-client-only'
import NoSsr from 'vue-no-ssr'
import { createRouter } from './router.js'
import NuxtChild from './components/nuxt-child.js'
import NuxtError from '../layouts/error.vue'
import Nuxt from './components/nuxt.js'
import App from './App.js'
import { setContext, getLocation, getRouteData, normalizeError } from './utils'

/* Plugins */

import nuxt_plugin_plugin_44af66af from 'nuxt_plugin_plugin_44af66af' // Source: ./composition-api/plugin.mjs (mode: 'all')
import nuxt_plugin_wmIcons_673d496f from 'nuxt_plugin_wmIcons_673d496f' // Source: ./wmIcons.js (mode: 'all')
import nuxt_plugin_nuxtpluginacaa9e9c_09b878d3 from 'nuxt_plugin_nuxtpluginacaa9e9c_09b878d3' // Source: ./nuxt.plugin.acaa9e9c.js (mode: 'server')
import nuxt_plugin_image_1c60c998 from 'nuxt_plugin_image_1c60c998' // Source: ./image.js (mode: 'all')
import nuxt_plugin_cookieuniversalnuxt_ad2edb76 from 'nuxt_plugin_cookieuniversalnuxt_ad2edb76' // Source: ./cookie-universal-nuxt.js (mode: 'all')
import nuxt_plugin_pluginutils_e5946184 from 'nuxt_plugin_pluginutils_e5946184' // Source: ./nuxt-i18n/plugin.utils.js (mode: 'all')
import nuxt_plugin_pluginseo_90070bdc from 'nuxt_plugin_pluginseo_90070bdc' // Source: ./nuxt-i18n/plugin.seo.js (mode: 'all')
import nuxt_plugin_pluginrouting_6600f5ee from 'nuxt_plugin_pluginrouting_6600f5ee' // Source: ./nuxt-i18n/plugin.routing.js (mode: 'all')
import nuxt_plugin_pluginmain_0321ab06 from 'nuxt_plugin_pluginmain_0321ab06' // Source: ./nuxt-i18n/plugin.main.js (mode: 'all')
import nuxt_plugin_paymentSveaPlugin_6bcc4adb from 'nuxt_plugin_paymentSveaPlugin_6bcc4adb' // Source: ./paymentSveaPlugin.js (mode: 'all')
import nuxt_plugin_postscribeclient_8fdd1438 from 'nuxt_plugin_postscribeclient_8fdd1438' // Source: ./postscribe.client.js (mode: 'client')
import nuxt_plugin_runtimeplugind78241e4_2497ee2a from 'nuxt_plugin_runtimeplugind78241e4_2497ee2a' // Source: ./runtime.plugin.d78241e4.mjs (mode: 'all')
import nuxt_plugin_customerplugin_951d70a0 from 'nuxt_plugin_customerplugin_951d70a0' // Source: ./customer.plugin.js (mode: 'all')
import nuxt_plugin_runtimeplugin257cf8a9_c6b500ec from 'nuxt_plugin_runtimeplugin257cf8a9_c6b500ec' // Source: ./runtime.plugin.257cf8a9.mjs (mode: 'all')
import nuxt_plugin_portalvue_6d5d0c54 from 'nuxt_plugin_portalvue_6d5d0c54' // Source: ./portal-vue.js (mode: 'all')
import nuxt_plugin_cookiebotplugin_66977a3d from 'nuxt_plugin_cookiebotplugin_66977a3d' // Source: ./cookiebot.plugin.js (mode: 'server')
import nuxt_plugin_dynamicContactForm_6e8c9994 from 'nuxt_plugin_dynamicContactForm_6e8c9994' // Source: ./dynamicContactForm.js (mode: 'all')
import nuxt_plugin_runtimeplugin3624c1b2_dac160d8 from 'nuxt_plugin_runtimeplugin3624c1b2_dac160d8' // Source: ./runtime.plugin.3624c1b2.mjs (mode: 'all')
import nuxt_plugin_runtimeplugin4d796bc5_34ebf0be from 'nuxt_plugin_runtimeplugin4d796bc5_34ebf0be' // Source: ./runtime.plugin.4d796bc5.mjs (mode: 'all')
import nuxt_plugin_runtimeplugin688c26b9_26e2398e from 'nuxt_plugin_runtimeplugin688c26b9_26e2398e' // Source: ./runtime.plugin.688c26b9.mjs (mode: 'all')
import nuxt_plugin_distpluginbb6e0e5a_4805cc67 from 'nuxt_plugin_distpluginbb6e0e5a_4805cc67' // Source: ./dist.plugin.bb6e0e5a.mjs (mode: 'all')
import nuxt_plugin_pluginsfc073575ee_99f1b9d4 from 'nuxt_plugin_pluginsfc073575ee_99f1b9d4' // Source: ./plugins.fc.073575ee.mjs (mode: 'all')
import nuxt_plugin_coreplugin0df8a4f0_7deb27e2 from 'nuxt_plugin_coreplugin0df8a4f0_7deb27e2' // Source: ./core.plugin.0df8a4f0.ts (mode: 'all')
import nuxt_plugin_pluginstokenexpired0d5bc035_1ca789d4 from 'nuxt_plugin_pluginstokenexpired0d5bc035_1ca789d4' // Source: ./plugins.token-expired.0d5bc035.ts (mode: 'all')
import nuxt_plugin_pluginsdompurify6162df75_c3f52aa2 from 'nuxt_plugin_pluginsdompurify6162df75_c3f52aa2' // Source: ./plugins.dompurify.6162df75.ts (mode: 'all')
import nuxt_plugin_pluginsi18n066d0a36_5f886002 from 'nuxt_plugin_pluginsi18n066d0a36_5f886002' // Source: ./plugins.i18n.066d0a36.ts (mode: 'all')
import nuxt_plugin_pluginsfixLocaleRoutesa98cdd66_0c201c4b from 'nuxt_plugin_pluginsfixLocaleRoutesa98cdd66_0c201c4b' // Source: ./plugins.fixLocaleRoutes.a98cdd66.ts (mode: 'all')
import nuxt_plugin_pluginse2etestinge817b860_38b95d1e from 'nuxt_plugin_pluginse2etestinge817b860_38b95d1e' // Source: ./plugins.e2e-testing.e817b860.js (mode: 'all')
import nuxt_plugin_deviceplugin_535d7728 from 'nuxt_plugin_deviceplugin_535d7728' // Source: ./device.plugin.js (mode: 'all')
import nuxt_plugin_workbox_782ce30e from 'nuxt_plugin_workbox_782ce30e' // Source: ./workbox.js (mode: 'client')
import nuxt_plugin_metaplugin_d3a566e8 from 'nuxt_plugin_metaplugin_d3a566e8' // Source: ./pwa/meta.plugin.js (mode: 'all')
import nuxt_plugin_iconplugin_ca561c00 from 'nuxt_plugin_iconplugin_ca561c00' // Source: ./pwa/icon.plugin.js (mode: 'all')
import nuxt_plugin_storeConfigPlugin_cbd09bc6 from 'nuxt_plugin_storeConfigPlugin_cbd09bc6' // Source: ../node_modules/@wemade-vsf/core/lib/runtime/plugins/storeConfigPlugin.mjs (mode: 'server')
import nuxt_plugin_meta_3161bcfe from 'nuxt_plugin_meta_3161bcfe' // Source: ./composition-api/meta.mjs (mode: 'all')

// Component: <ClientOnly>
Vue.component(ClientOnly.name, ClientOnly)

// TODO: Remove in Nuxt 3: <NoSsr>
Vue.component(NoSsr.name, {
  ...NoSsr,
  render (h, ctx) {
    if (process.client && !NoSsr._warned) {
      NoSsr._warned = true

      console.warn('<no-ssr> has been deprecated and will be removed in Nuxt 3, please use <client-only> instead')
    }
    return NoSsr.render(h, ctx)
  }
})

// Component: <NuxtChild>
Vue.component(NuxtChild.name, NuxtChild)
Vue.component('NChild', NuxtChild)

// Component NuxtLink is imported in server.js or client.js

// Component: <Nuxt>
Vue.component(Nuxt.name, Nuxt)

Object.defineProperty(Vue.prototype, '$nuxt', {
  get() {
    const globalNuxt = this.$root ? this.$root.$options.$nuxt : null
    if (process.client && !globalNuxt && typeof window !== 'undefined') {
      return window.$nuxt
    }
    return globalNuxt
  },
  configurable: true
})

Vue.use(Meta, {"keyName":"head","attribute":"data-n-head","ssrAttribute":"data-n-head-ssr","tagIDKeyName":"hid"})

const defaultTransition = {"name":"page","mode":"out-in","appear":false,"appearClass":"appear","appearActiveClass":"appear-active","appearToClass":"appear-to"}

async function createApp(ssrContext, config = {}) {
  const store = null
  const router = await createRouter(ssrContext, config, { store })

  // Create Root instance

  // here we inject the router and store to all child components,
  // making them available everywhere as `this.$router` and `this.$store`.
  const app = {
    head: {"title":"OFT","titleTemplate":"%s - OFT","meta":[{"charset":"utf8"},{"name":"viewport","content":"width=device-width, initial-scale=1"},{"hid":"description","name":"description","content":""},{"hid":"og:site_name","property":"og:site_name","name":"og:site_name","content":"OFT"},{"hid":"og:title","name":"og:title","property":"og:title","content":"","template":(chunk) => chunk ? `${chunk} - OFT` : 'OFT'},{"hid":"og:description","property":"og:description","content":""},{"hid":"apple-mobile-web-app-title","property":"apple-mobile-web-app-title","content":"OFT"},{"name":"og:image","hid":"og:image","property":"og:image","content":"\u002Fogimage.jpg"},{"name":"generator","content":"Vue Storefront 2"}],"link":[{"rel":"icon","type":"image\u002Fx-icon","href":"\u002Ffavicon.ico"}],"bodyAttrs":{"id":"html-body"},"style":[],"script":[]},

    router,
    nuxt: {
      defaultTransition,
      transitions: [defaultTransition],
      setTransitions (transitions) {
        if (!Array.isArray(transitions)) {
          transitions = [transitions]
        }
        transitions = transitions.map((transition) => {
          if (!transition) {
            transition = defaultTransition
          } else if (typeof transition === 'string') {
            transition = Object.assign({}, defaultTransition, { name: transition })
          } else {
            transition = Object.assign({}, defaultTransition, transition)
          }
          return transition
        })
        this.$options.nuxt.transitions = transitions
        return transitions
      },

      err: null,
      dateErr: null,
      error (err) {
        err = err || null
        app.context._errored = Boolean(err)
        err = err ? normalizeError(err) : null
        let nuxt = app.nuxt // to work with @vue/composition-api, see https://github.com/nuxt/nuxt.js/issues/6517#issuecomment-573280207
        if (this) {
          nuxt = this.nuxt || this.$options.nuxt
        }
        nuxt.dateErr = Date.now()
        nuxt.err = err
        // Used in src/server.js
        if (ssrContext) {
          ssrContext.nuxt.error = err
        }
        return err
      }
    },
    ...App
  }

  const next = ssrContext ? ssrContext.next : location => app.router.push(location)
  // Resolve route
  let route
  if (ssrContext) {
    route = router.resolve(ssrContext.url).route
  } else {
    const path = getLocation(router.options.base, router.options.mode)
    route = router.resolve(path).route
  }

  // Set context to app.context
  await setContext(app, {
    route,
    next,
    error: app.nuxt.error.bind(app),
    payload: ssrContext ? ssrContext.payload : undefined,
    req: ssrContext ? ssrContext.req : undefined,
    res: ssrContext ? ssrContext.res : undefined,
    beforeRenderFns: ssrContext ? ssrContext.beforeRenderFns : undefined,
    beforeSerializeFns: ssrContext ? ssrContext.beforeSerializeFns : undefined,
    ssrContext
  })

  function inject(key, value) {
    if (!key) {
      throw new Error('inject(key, value) has no key provided')
    }
    if (value === undefined) {
      throw new Error(`inject('${key}', value) has no value provided`)
    }

    key = '$' + key
    // Add into app
    app[key] = value
    // Add into context
    if (!app.context[key]) {
      app.context[key] = value
    }

    // Check if plugin not already installed
    const installKey = '__nuxt_' + key + '_installed__'
    if (Vue[installKey]) {
      return
    }
    Vue[installKey] = true
    // Call Vue.use() to install the plugin into vm
    Vue.use(() => {
      if (!Object.prototype.hasOwnProperty.call(Vue.prototype, key)) {
        Object.defineProperty(Vue.prototype, key, {
          get () {
            return this.$root.$options[key]
          }
        })
      }
    })
  }

  // Inject runtime config as $config
  inject('config', config)

  // Add enablePreview(previewData = {}) in context for plugins
  if (process.static && process.client) {
    app.context.enablePreview = function (previewData = {}) {
      app.previewData = Object.assign({}, previewData)
      inject('preview', previewData)
    }
  }
  // Plugin execution

  if (typeof nuxt_plugin_plugin_44af66af === 'function') {
    await nuxt_plugin_plugin_44af66af(app.context, inject)
  }

  if (typeof nuxt_plugin_wmIcons_673d496f === 'function') {
    await nuxt_plugin_wmIcons_673d496f(app.context, inject)
  }

  if (process.server && typeof nuxt_plugin_nuxtpluginacaa9e9c_09b878d3 === 'function') {
    await nuxt_plugin_nuxtpluginacaa9e9c_09b878d3(app.context, inject)
  }

  if (typeof nuxt_plugin_image_1c60c998 === 'function') {
    await nuxt_plugin_image_1c60c998(app.context, inject)
  }

  if (typeof nuxt_plugin_cookieuniversalnuxt_ad2edb76 === 'function') {
    await nuxt_plugin_cookieuniversalnuxt_ad2edb76(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginutils_e5946184 === 'function') {
    await nuxt_plugin_pluginutils_e5946184(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginseo_90070bdc === 'function') {
    await nuxt_plugin_pluginseo_90070bdc(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginrouting_6600f5ee === 'function') {
    await nuxt_plugin_pluginrouting_6600f5ee(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginmain_0321ab06 === 'function') {
    await nuxt_plugin_pluginmain_0321ab06(app.context, inject)
  }

  if (typeof nuxt_plugin_paymentSveaPlugin_6bcc4adb === 'function') {
    await nuxt_plugin_paymentSveaPlugin_6bcc4adb(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_postscribeclient_8fdd1438 === 'function') {
    await nuxt_plugin_postscribeclient_8fdd1438(app.context, inject)
  }

  if (typeof nuxt_plugin_runtimeplugind78241e4_2497ee2a === 'function') {
    await nuxt_plugin_runtimeplugind78241e4_2497ee2a(app.context, inject)
  }

  if (typeof nuxt_plugin_customerplugin_951d70a0 === 'function') {
    await nuxt_plugin_customerplugin_951d70a0(app.context, inject)
  }

  if (typeof nuxt_plugin_runtimeplugin257cf8a9_c6b500ec === 'function') {
    await nuxt_plugin_runtimeplugin257cf8a9_c6b500ec(app.context, inject)
  }

  if (typeof nuxt_plugin_portalvue_6d5d0c54 === 'function') {
    await nuxt_plugin_portalvue_6d5d0c54(app.context, inject)
  }

  if (process.server && typeof nuxt_plugin_cookiebotplugin_66977a3d === 'function') {
    await nuxt_plugin_cookiebotplugin_66977a3d(app.context, inject)
  }

  if (typeof nuxt_plugin_dynamicContactForm_6e8c9994 === 'function') {
    await nuxt_plugin_dynamicContactForm_6e8c9994(app.context, inject)
  }

  if (typeof nuxt_plugin_runtimeplugin3624c1b2_dac160d8 === 'function') {
    await nuxt_plugin_runtimeplugin3624c1b2_dac160d8(app.context, inject)
  }

  if (typeof nuxt_plugin_runtimeplugin4d796bc5_34ebf0be === 'function') {
    await nuxt_plugin_runtimeplugin4d796bc5_34ebf0be(app.context, inject)
  }

  if (typeof nuxt_plugin_runtimeplugin688c26b9_26e2398e === 'function') {
    await nuxt_plugin_runtimeplugin688c26b9_26e2398e(app.context, inject)
  }

  if (typeof nuxt_plugin_distpluginbb6e0e5a_4805cc67 === 'function') {
    await nuxt_plugin_distpluginbb6e0e5a_4805cc67(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginsfc073575ee_99f1b9d4 === 'function') {
    await nuxt_plugin_pluginsfc073575ee_99f1b9d4(app.context, inject)
  }

  if (typeof nuxt_plugin_coreplugin0df8a4f0_7deb27e2 === 'function') {
    await nuxt_plugin_coreplugin0df8a4f0_7deb27e2(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginstokenexpired0d5bc035_1ca789d4 === 'function') {
    await nuxt_plugin_pluginstokenexpired0d5bc035_1ca789d4(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginsdompurify6162df75_c3f52aa2 === 'function') {
    await nuxt_plugin_pluginsdompurify6162df75_c3f52aa2(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginsi18n066d0a36_5f886002 === 'function') {
    await nuxt_plugin_pluginsi18n066d0a36_5f886002(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginsfixLocaleRoutesa98cdd66_0c201c4b === 'function') {
    await nuxt_plugin_pluginsfixLocaleRoutesa98cdd66_0c201c4b(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginse2etestinge817b860_38b95d1e === 'function') {
    await nuxt_plugin_pluginse2etestinge817b860_38b95d1e(app.context, inject)
  }

  if (typeof nuxt_plugin_deviceplugin_535d7728 === 'function') {
    await nuxt_plugin_deviceplugin_535d7728(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_workbox_782ce30e === 'function') {
    await nuxt_plugin_workbox_782ce30e(app.context, inject)
  }

  if (typeof nuxt_plugin_metaplugin_d3a566e8 === 'function') {
    await nuxt_plugin_metaplugin_d3a566e8(app.context, inject)
  }

  if (typeof nuxt_plugin_iconplugin_ca561c00 === 'function') {
    await nuxt_plugin_iconplugin_ca561c00(app.context, inject)
  }

  if (process.server && typeof nuxt_plugin_storeConfigPlugin_cbd09bc6 === 'function') {
    await nuxt_plugin_storeConfigPlugin_cbd09bc6(app.context, inject)
  }

  if (typeof nuxt_plugin_meta_3161bcfe === 'function') {
    await nuxt_plugin_meta_3161bcfe(app.context, inject)
  }

  // Lock enablePreview in context
  if (process.static && process.client) {
    app.context.enablePreview = function () {
      console.warn('You cannot call enablePreview() outside a plugin.')
    }
  }

  // Wait for async component to be resolved first
  await new Promise((resolve, reject) => {
    // Ignore 404s rather than blindly replacing URL in browser
    if (process.client) {
      const { route } = router.resolve(app.context.route.fullPath)
      if (!route.matched.length) {
        return resolve()
      }
    }
    router.replace(app.context.route.fullPath, resolve, (err) => {
      // https://github.com/vuejs/vue-router/blob/v3.4.3/src/util/errors.js
      if (!err._isRouter) return reject(err)
      if (err.type !== 2 /* NavigationFailureType.redirected */) return resolve()

      // navigated to a different route in router guard
      const unregister = router.afterEach(async (to, from) => {
        if (process.server && ssrContext && ssrContext.url) {
          ssrContext.url = to.fullPath
        }
        app.context.route = await getRouteData(to)
        app.context.params = to.params || {}
        app.context.query = to.query || {}
        unregister()
        resolve()
      })
    })
  })

  return {
    app,
    router
  }
}

export { createApp, NuxtError }
