





































































































































import {
  defineComponent,
  useFetch,
  ref,
  useContext
} from '@nuxtjs/composition-api';
import { useDynamicContactForm, DynamicContactForm, Field } from '@wemade-vsf/dynamic-contact-form';
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate';
import { required, email, min, max } from 'vee-validate/dist/rules';
import SkeletonLoader from '@wemade-vsf/theme/templates/components/SkeletonLoader.vue';
import { SfButton } from '@storefront-ui/vue';

extend('email', {
  ...email,
  message: 'Invalid email',
});

extend('required', {
  ...required,
  message: 'This field is required',
});

extend('min', {
  ...min,
  message: 'The field should have at least {length} characters',
});

extend('max', {
  ...max,
  message: 'The field should have at most {length} characters',
});

export default defineComponent({
  name: 'DynamicContactForm',
  props: {
    uid: {
      type: String,
      required: true
    },
    buttonText: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    }
  },
  components: {
    SkeletonLoader,
    ValidationObserver,
    ValidationProvider,
    SfButton
  },
  setup(props) {
    const { app } = useContext();
    const { send, load, loading, error } = useDynamicContactForm();
    const form = ref<DynamicContactForm | null>(null);
    const formData = ref<Record<string, any>>({})
    const validationObserver = ref(null)
    const successMessage = ref('')
    const errorMessage = ref('')

    function buildFormData() {
      if (!form.value) return;
      form.value.fields.forEach(field => {
        switch(field.type) {
          case 'checkbox':
              formData.value[field.uid] = false;
              break;
          case 'radio':
              formData.value[field.uid] = field.options.length ? field.options[0] : '';
              break;
          default:
            formData.value[field.uid] = ''
        }
      })
    }

    function getRules(field: Field): Record<string, any> {
      const isString = ['email', 'string', 'textarea'].includes(field.type);
      return {
        required: field.required,
        email: field.type === 'email',
        ...isString && field.min_length ? { min: field.min_length } : {},
        ...isString && field.max_length ? { max: field.max_length } : {}
      };
    }

    async function onSubmit() {
      if (!form.value) return;
      const email = form.value?.fields.find(f => f.type === 'email');
      let senderEmail = '';
      if (email && formData.value[email.uid]) {
        senderEmail = formData.value[email.uid];
      }
      let htmlBody = '<table width="100%">';
      form.value.fields.forEach(field => {
        let value = formData.value[field.uid];
        if (field.type === 'checkbox') {
          value = value ? app.i18n.t('Yes') : app.i18n.t('No');
        }
        htmlBody += `<tr>
          <th scope="row">${field.label}</th>
          <td>${value}</td>
          </tr>`
      });
      htmlBody += '</table>';
      const result = await send({
        uid: props.uid,
        htmlBody,
        senderEmail
      });

      if (result && !error.value.send) {
        successMessage.value = app.i18n.t('Your email has been sent');
        buildFormData();
        validationObserver.value.reset()
      } else {
        errorMessage.value = app.i18n.t('An error occurred while processing your request. Please try again.');
      }
    }

    useFetch(async() => {
      form.value = await load(props.uid);
      buildFormData()
    });

    return {
      form,
      formData,
      getRules,
      onSubmit,
      loading,
      successMessage,
      errorMessage,
      validationObserver
    }
  }
})
