import { render, staticRenderFns } from "./DynamicContactForm.vue?vue&type=template&id=da4fa99a&scoped=true&"
import script from "./DynamicContactForm.vue?vue&type=script&lang=ts&"
export * from "./DynamicContactForm.vue?vue&type=script&lang=ts&"
import style0 from "./DynamicContactForm.vue?vue&type=style&index=0&id=da4fa99a&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "da4fa99a",
  null
  
)

export default component.exports